@import 'colors';
@import 'bootstrap';
@import 'variables';

body {
    margin: 0;
    background-image: url('../../public/images/background.jpg');
    background-size: cover;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        content: '';
        position: absolute;
        height: 100vh;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.85;
    }

    li::marker {
        color: $rk-secondary;
    }

    .offcanvas {

        .navbar-nav {

            .nav-link.active,
            .nav-link:hover {
                color: $rk-secondary;
            }
        }
    }
}