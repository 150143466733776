@use '/src/styles/colors';
@use '/src/styles/variables';

.accordion-header {

    button {
        &:after {
            margin-left: 10px;
        }

        &.collapsed {
            .rk-employer-name {
                color: colors.$rk-secondary;
            }
        }

        .rk-employer-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: variables.$rk-font-light;
        }
    }
}

.rk-employer-body {

    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: variables.$rk-line-height;
    font-weight: variables.$rk-font-light;

    ul {
        margin-bottom: 0;
        padding-left: 1rem;
    }

    .rk-employer-image {

        display: flex;
        align-items: center;
        justify-content: center;
        width: variables.$rk-max-logo-size;
        height: variables.$rk-max-logo-size;
        background-color: white;
        padding: 20px;
        border-radius: 50%;

        img {
            width: 100%;
        }
    }
}