@use 'src/styles/variables';

.rk-summary-point {

    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: variables.$rk-font-light;

    img {
        width: 25px;
        margin-right: 30px;
    }
}