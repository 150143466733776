@use '/src/styles/colors';
@use '/src/styles/variables';

.rk-qualification-item {

    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-weight: variables.$rk-font-light;

    .rk-qualification-detail {
        display: flex;
        align-items: center;
        margin-bottom: .7rem;

        img {
            width: 25px;
            margin-right: 10px;
        }
    }
}